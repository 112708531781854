.banner-nav {
    position: absolute;
    bottom: 120px;
    width: 100%;
    z-index: 1;
}

.banner-nav.bottom{
    bottom: 0px;
}

.banner-nav .main {
    padding-bottom: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1200px;
    margin: 0px auto;
}

.line {
    border-bottom: 1px solid #FFF;
}

.banner-nav .main .banner_arr .banner_arr_l{
    transform: scaleX(-1);
    display: inline-block;
    width: 54px;
    height: 54px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center left;
    background-image: url(../static/image/icon_arrow_r.png);
    margin: 0px 24px 0px 0px;
}

.banner-nav .main .banner_arr .banner_arr_r{
     display: inline-block;
     width: 54px;
     height: 54px;
     background-size: contain;
     background-repeat: no-repeat;
     background-position: center left;
     background-image: url(../static/image/icon_arrow_r.png);
     margin: 0px 24px 0px 0px;
}


.banner-nav .main .banner_arr span{
    cursor: pointer;
}

.banner-nav .banner_bullet{

}

.swiper-pagination-clickable .swiper-pagination-bullet {
    cursor: pointer;
}


.swiper-pagination-bullet {
    width: 8px;
    height: 8px;
    display: inline-block;
    background: #000;
    opacity: .2;
}

.swiper-pagination-bullet {
    border-radius: 0px;
    background: #FFF;
    opacity: 1;
    margin-left: 12px;
    width: 10px;
    height: 10px;
    transition: ease all 0.3s;
}

.swiper-pagination-bullet-active {
    width: 30px;
    background: #396ae3;
}

@media screen and (max-width: 960px){
    .banner-nav{
        margin: 0px 24px;
        width: 87%;
    }
}
.banner_slide img{
    border: 0;
    vertical-align: bottom;
}

.yy-swiper-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    transition-property: transform;
    box-sizing: content-box;
}

.swiper-container {
    margin-left: auto;
    margin-right: auto;
    position: relative;
    overflow: hidden;
    list-style: none;
    padding: 0;
    z-index: 1;
}

.banner_slide{
    height: 720px;
    position: relative;
}

.banner_slide .banner_img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.banner_shadow {
    display: block;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.5);
}

.banner_slide .banner_text {
    position: absolute;
    top: 0px;
    padding-bottom: 120px;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.1);
    color: #FFF;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.yy_main {
    width: 100%;
    max-width: 1200px;
    margin: 0px auto;
}

.yy_main h4{
    font-size: 28px;
    font-weight: 500;
    color: #FFF;
    margin-bottom: 12px;
}

.banner h5{
    font-size: 18px;
    line-height: 1.8em;
    margin: 12px 0px;
    padding: 0px;
    font-weight: normal;
    font-variant: normal;
}

.banner h2{
    max-width: 950px;
    font-weight: normal;
    font-size: 48px;
    line-height: 1.4em;
    margin: 12px 0px;
    padding: 0px;
    font-variant: normal;
}


@media screen and (max-width: 960px) {
    .banner h2{
        font-size: 30px;
        line-height: 1.4em;
    }

    .banner h5{
        font-size: 18px;
        line-height: 1.8em;
    }

    .yy_main{
        margin: 0px 24px;
        width: auto;
    }
}



.banner .swiper-slide h2 {
    opacity: 0;
    transition: ease all 0.5s;
    transform: translate(120px);
    transition-delay: 0.2s;
}

.banner .swiper-slide h5 {
    opacity: 0;
    max-width: 680px;
    font-family: 'PingFangSC-Regular', 'helvetica neue', tahoma, 'PingFang SC', 'microsoft yahei', arial, 'hiragino sans gb', sans-serif;
    transition: ease all 0.5s;
    transform: translate(120px);
    transition-delay: 0.4s;
}

.banner .swiper-slide-active h2 {
    opacity: 1;
    transform: translate(0px);
}

.banner .swiper-slide-active h5{
    opacity: 1;
    transform: translate(0px);
}
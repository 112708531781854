.logo_yunying{
    display: block;
    height: 28px;
    width: 212px;
    position: absolute;
    right: 75px;
    top: 50%;
    margin-top: -21px;
    opacity: 1;
    transition: ease all 0.5s;
}

@media screen and (max-width: 700px){
    .logo_yunying{
        opacity: 0;
    }
}
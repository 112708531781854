html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td {
    margin: 0;
    padding: 0;
}

.footer_area {
    display: block;
    width: 100%;
    position: absolute;
    bottom: 24px;
    left: 0;
}

@media screen and (max-width: 960px){
    .footer_area {
        display: block;
        width: 100%;
        position: absolute;
        padding: 30px;
        bottom: 24px;
        left: 0;
    }
}

.footer_area .links li{
    display: inline-block;
    float: left;
    margin-right: 20px;
}

.footer_area .links a{
    font-size: 14px;
    color: #5f6464;
    margin-bottom: 0px;
}

.footer_area .copyright{
    font-size: 14px;
    color: #5f6464;
    float: left;
    width: 100%;
    margin-top: 5px;
}

p{
    margin-top: 0;
    padding: 0;
}

*{
    box-sizing: border-box;
}
.focus_us {
    display: block;
    height: 55px;
    width: 100%;
    border-bottom: 1px solid #f2f3f5;
    padding-top: 33px;
    margin-bottom: 40px;
    box-sizing: content-box;
}

.focus_us .tit{
    float: left;
    margin-right: 33px;
    display: block;
    font-size: 20px;
    color: #5f6464;
    font-weight: normal;
    margin-bottom: 30px;
}

@media screen and (max-width: 960px){
    .focus_us .tit{
        float: left;
        margin-right: 10px;
        display: block;
        font-size: 20px;
        color: #5f6464;
        font-weight: normal;
        margin-bottom: 30px;
    }
}

ul, li {
    list-style: none;
}

.focus_us .focus_list li a {
    display: block;
    height: 28px;
    width: 28px;
}

a, a:hover, a:active, a:visited, a:link, a:focus {
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-tap-highlight-color: transparent;
    outline: none;
    background: none;
    text-decoration: none;
}

.focus_us .focus_list li {
    display: inline-block;
    float: left;
    margin-right: 28px;
}


.focus_us .focus_list .wechat-btn .icon{
    display: block;
    height: 28px;
    width: 28px;
    background: url(../static/image/icon_wechat.png) no-repeat center;
    background-size: auto;
    background-size: 100% 100%;
    position: relative;
    transition: background 0.2s ease-in-out;
}

.focus_us .focus_list li a:hover .icon {
    background: url(../static/image/icon_wechat_hover.png) no-repeat center;
    background-size: auto;
    background-size: 100% 100%;
}

.focus_us .focus_list li a:hover .wechat-code{
    display: block;
    opacity: 1;
}

.wechat-code{
    width: 176px;
    position: absolute;
    left: 0;
    top: -186px;
    z-index: 2;
    display: block;
    display: none\9;
    opacity: 0;
    transition: opacity 0.2s linear;
    pointer-events: none;
}
.header-seat{
    padding-bottom: 72px;
    transition: .25s ease;
}

.null_content {
    min-height: 600px;
}

.f_crumb{
    min-height: 30px;
    background-color: #f2f3f5;
    line-height: 56px;
    font-size: 16px;
}

.f_crumb .crumb {
    width: 1200px;
    margin: auto;
    position: relative;
    overflow: hidden;
}

.f-content {
    width: 1200px;
    margin: auto;
    position: relative;
    overflow: hidden;
}

@media screen and (min-width: 1199px) {
    .page-content {
        width: 840px;
        padding-left: 144px;
    }
}

.f-content .page-content .title-3{
    margin-bottom: 40px;
    margin-top: 30px;
    padding: 10px 0px;
    border-bottom: 1px solid #D8D9DC;
}

.title-3 .main {
    font-size: 44px;
    line-height: 66px;
    padding-bottom: 7px;
    font-weight: 500;
    font-variant-ligatures: no-common-ligatures;
}

h1,h2,h3,h4,h5 {
    font-variant: normal;
    font-variant-ligatures: normal;
}

.title-3 .date {
    line-height: 25px;
    color: #919797;
}

.data{
    padding-bottom: 110px;
}

.text {
    line-height: 25px;
}

.color-1{
    color: #5F6464;
}

.text p {
    padding-bottom: 29px;
}

.text .color-1 p{
    text-align: justify;
}
.cookie-box{
    background: rgba(0,0,0,.7);
    bottom: 0;
    height: 14.75em;
    position: fixed;
    width: 100%;
    z-index: 99;
}

.cookie-box .box-top{
    height: 70%;
    padding: 2.5em 5em;
    width: 100%;
    line-height: 1.5;
}

.cookie-box .box-top .title{
    font-size: 1.5em;
    font-weight: 600;
    margin-bottom: .75em;
}

.cookie-box .box-top .content-box{
    color: #fff;
    font-size: 1.25em;
    margin: 0 auto 0.125em;
    width: 90em;
}

.cookie-box .btn-box{
    display: flex;
    margin: 0 auto;
    position: relative;
    width: 90em;
    bottom: 1em;
}

.cookie-box .btn-box .accept-btn{
    right: 18.75em;
    background-color: #f7f7f7;
    color: #222;
    cursor: pointer;
    font-size: 1.25em;
    height: 3.25em;
    line-height: 3.25em;
    position: absolute;
    text-align: center;
    width: 12.5em;
}

.cookie-box .btn-box .reject-btn{
    right: 4.375em;
    background-color: #f7f7f7;
    color: #222;
    cursor: pointer;
    font-size: 1.25em;
    height: 3.25em;
    line-height: 3.25em;
    position: absolute;
    text-align: center;
    width: 12.5em;
}
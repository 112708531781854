

.whats_new {
  background: #fbfbfb;
}

.wrap_footer{
  width: 100%;
  height: 480px;
  background: #fbfbfb;
  position: relative;
  z-index: 1;
}

.footer{
  width: 1200px;
  margin: 0px auto;
  height: 480px;
  position: relative;
}
@media screen and (max-width: 960px){
  .footer{
    width: 100%;
    margin: 0px auto;
    padding: 30px;
    height: 480px;
    position: relative;
  }
}


.footer .logo_yunying img {
  width: 100%;
}

img{
  border: 0;
  vertical-align: bottom;
}

ul{
  list-style: none;
}


div {
  padding: 0;
}

a{
  outline: none;
  background: none;
  text-decoration: none;
}

.cards{
  position: relative;
  z-index: 1;
  margin-top: -120px;
  padding-bottom: 60px;
}

.cards .card {
  margin-bottom: 24px;
  height: auto;
  width: 100%;
  color: #2b2e2e;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.white {
  background: white;
}

#root h4{
  line-height: 1.5em;
  margin: 12px 0px;
  margin-bottom: 12px;
  padding: 0px;
}

.yy_main{
  width: 100%;
  max-width: 1200px;
  margin: 0px auto;
}

.yy_main h4{
  font-size: 28px;
  font-weight: 500;
  color: #FFF;
  margin-bottom: 12px;
}

.return-top{
  z-index: 4;
  display: block;
  height: 50px;
  width: 50px;
  line-height: 50px;
  text-align: center;
  color: #375a7f;
  font-size: 18px;
  background-color: #eee;
  border: 1px solid #ddd;
  border-radius: 3px;
  cursor: pointer;
  font-family: SimHei, serif;
}
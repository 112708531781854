.join_us {
    float: left;
    width: 216px;
}

.tit{
    display: block;
    font-size: 20px;
    color: #5f6464;
    font-weight: normal;
    margin-bottom: 30px;
}

.footer_list_item li a{
    display: block;
    font-size: 16px;
    color: #5f6464;
    font-weight: normal;
    margin-bottom: 18px;
}

.contact_us {
    float: left;
    width: 216px;
}
.white {
    background-color: white;
}

.hide {
    top: -72px;
}

.NavBar {
    left: 0;
    display: block;
    width: 1200px;
    height: 72px;
    margin: 0px auto;
    position: relative;
    z-index: 2;
}

@media screen and (max-width: 960px) {
    .NavBar {
        left: 0;
        display: block;
        width: 100%;
        height: 72px;
        margin: 0px auto;
        position: relative;
        z-index: 2;
    }
}


.NavBar .logo {
    top: 18px;
    display: block;
    height: 40px;
    width: 168px;
    position: absolute;
    left: 10px;

    transition: ease all 0.5s;
}

@media screen and (max-width: 960px) {
    .NavBar .logo {
        top: 18px;
        display: block;
        height: 20px;
        width: 84px;
        position: absolute;
        left: 50%;
        margin-left: -78px;
    }
}


.menu_toggle {
    display: block;
    height: 20px;
    width: 20px;
    position: absolute;
    left: 27px;
    top: 24px;
    z-index: 99;
    background-color: transparent;
    border: none;

    transition: ease all 0.5s;
}

@media screen and (min-width: 960px){
    .menu_toggle {
        display: none;
    }
}

.white .i_menu {
    height: 20px;
    width: 20px;
    background: url(../static/image/icon_menu_hover.png) no-repeat 0 0;
    background-size: auto;
    background-size: contain;
    position: absolute;
    left: 0px;
    top: 0px;
    z-index: 2;
}

.i_menu {
    height: 20px;
    width: 20px;
    background: url(../static/image/icon_menu.png) no-repeat 0 0;
    background-size: auto;
    background-size: contain;
    position: absolute;
    left: 0px;
    top: 0px;
    z-index: 2;
}

.NavBar h1 {
    display: block;
    height: 40px;
    width: 168px;
    background: url(../static/image/webLogo-hover.png) no-repeat 0 0;
    text-indent: -9999px;
    overflow: hidden;
    background-size: 100% 100%;
}

.white h1 {
    display: block;
    height: 40px;
    width: 168px;
    background: url(../static/image/webLogo.png) no-repeat 0 0;
    text-indent: -9999px;
    overflow: hidden;
    background-size: 100% 100%;
}

@media screen and (max-width: 960px) {
    .NavBar h1 {
        display: block;
        height: 30px;
        width: 126px;
        background: url(../static/image/webLogo-hover.png) no-repeat 0 0;
        text-indent: -9999px;
        overflow: hidden;
        background-size: 100% 100%;
    }

    .white h1{
        display: block;
        height: 30px;
        width: 126px;
        background: url(../static/image/webLogo.png) no-repeat 0 0;
        text-indent: -9999px;
        overflow: hidden;
        background-size: 100% 100%;
    }
}



ul {
    list-style: none;
}

.menu_list{
    display: none;
}

.show{
    display: block;
}


@media screen and (min-width: 960px) {
    .menu_list {
        right: 150px;
        display: block;
        height: 72px;
        position: absolute;
        margin: 0;
        top: 0;
        cursor: pointer;
        transition: opacity 0.3s linear;
    }

    .menu_list .menu_item {
        margin-left: 72px;
        height: 72px;
        float: left;
        position: relative;
    }

    .menu_list .menu_item .txt {
        display: block;
        height: 72px;
        line-height: 72px;
        font-size: 16px;
        color: white;
        font-weight: bold;
        opacity: 0.95;
        -webkit-transition: color 0.3s linear;
        position: relative;
        z-index: 2;
    }

    .white .menu_list .menu_item .txt {
        display: block;
        height: 72px;
        line-height: 72px;
        font-size: 16px;
        color: black;
        font-weight: bold;
        opacity: 0.95;
        -webkit-transition: color 0.3s linear;
        position: relative;
        z-index: 2;
    }

    .menu_list .menu_item .txt::after {
        display: block;
        content: "";
        height: 4px;
        width: 100%;
        position: absolute;
        bottom: 19px;
        left: 0px;
        background: #194ac3;
        opacity: 0;
        -webkit-transition: transform 0.2s ease-out, opacity 0.2s ease-out;
        transform-origin: 50% 0%;
        transform: scale(0.01, 1);
    }
}



body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.wrap{
  display: block;
  width: 100%;
  position: relative;
  overflow-x: hidden;
}

.menu_toggle{
  display: block;
  height: 20px;
  width: 20px;
  position: absolute;
  left: 21px;
  top: 18px;
  z-index: 99;
}


.menu_toggle .wrap_head{
  display: block;
  position: fixed;
  top: 0;
  width: 100%;
  height: 72px;
  z-index: 2;
  left: 0;
  -moz-transition: all 0.2s linear;
}

.wrap_head{
  display: block;
  position: fixed;
  top: 0;
  width: 100%;
  height: 72px;
  z-index: 2;
  left: 0;
  -moz-transition: all 0.2s linear;
}

body, html {
  color: #2b2e2e;
  font-size: 14px;
  line-height: 1.5em;
  font-family: 'PingFangSC-Regular', 'helvetica neue', tahoma, 'PingFang SC', 'microsoft yahei', arial, 'hiragino sans gb', sans-serif;
}
